var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-space-between align-center"},[(_vm.item.type === 'processingActivity')?_c('UserTaskTypeProcessingActivity',{attrs:{"shortDate":_vm.shortDate,"fullDate":_vm.fullDate,"item":_vm.item,"checkboxModel":_vm.checkboxModel,"is-user-task":_vm.isUserTask},on:{"close":function($event){return _vm.$emit('close')}}}):(_vm.item.type === 'todo')?_c('UserTaskTypeTodo',{attrs:{"shortDate":_vm.shortDate,"fullDate":_vm.fullDate,"item":_vm.item,"checkboxModel":_vm.checkboxModel},on:{"toggle-checkbox":function($event){_vm.checkboxModel = !_vm.checkboxModel}}}):(_vm.item.type === 'feed')?_c('UserTaskTypeFeed',{attrs:{"shortDate":_vm.shortDate,"fullDate":_vm.fullDate,"item":_vm.item,"checkboxModel":_vm.checkboxModel},on:{"toggle-checkbox":function($event){_vm.checkboxModel = !_vm.checkboxModel}}}):_vm._e(),(_vm.checkboxModel || _vm.item.type === 'feed')?_c('div',[(_vm.item.type === 'feed')?_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"color":"primary","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"pa-0 ma-0",attrs:{"small":"","icon":"","tag":"a","to":{
                            name: 'CommunityList',
                            params: {
                                category: _vm.item.feedType,
                                id: _vm.item.entityId,
                            },
                        },"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v("open_in_new")])],1)],1)]}}],null,false,3435713407)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('dashboard.community.showEntryDetailsInNewTab'))}})]):_vm._e(),(_vm.removeable && _vm.checkboxModel)?_c('v-tooltip',{staticClass:"d-inline-flex",attrs:{"left":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"pa-0 ml-2 ma-0 mt-n7",attrs:{"small":"","icon":"","tag":"a"},on:{"click":function($event){return _vm.removeTask()}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("clear")])],1)],1)]}}],null,false,587780651)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('dashboard.tasks.remove'))}})]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }