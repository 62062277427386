<template>
    <div class="d-flex flex-row">
        <div
            class="taskState pr-3"
            @click="onClick"
            :class="{'cursor-pointer': item.entityId, 'left': checkboxModel}"
        >
            <v-icon v-if="['open', 'reopened'].includes(item.status)" medium v-text="'check_box_outline_blank'" />
            <v-icon v-else medium color="primary" v-text="item.status === 'skipped' ? 'mdi-progress-check' : 'check'" />
        </div>
        <div>
            <div v-if="item.entityId" class="pl-0">
                <i18n :path="isUserTask ? 'dashboard.tasks.message' : 'dashboard.consultantTasks.message'" tag="p" class="pa-0 ma-0" :class="item.status === 'closed' || item.status === 'skipped' ? 'line-through' : ''">
                    <template v-slot:name>
                        {{ fromUserName }}
                    </template>
                    <template v-slot:typeTitle>
                      {{ $t('dashboard.tasks.types.' + item.type + '.title') }}
                    </template>
                    <template v-slot:link>
                        <span class="font-weight-bold undecorated-link">
                            <router-link
                                @click.native="$event.stopImmediatePropagation()"
                                :to="{
                                    name: 'VvtEditProcessingActivity',
                                    params: { id: item.entityId },
                                    query: { workflow: item.workflowTaskId },
                                }"
                                >{{ item.processingActivityName }}</router-link
                            >
                        </span>
                    </template>
                </i18n>
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="d-inline-block">
                            <div
                                class="text-caption"
                                :class="{ 'grey--text': $vuetify.dark }"
                                v-text="shortDate"
                            />
                        </div>
                    </template>
                    <span v-text="fullDate"></span>
                </v-tooltip>
            </div>
            <div v-else class="pl-0">
                {{ $t(isUserTask ? 'dashboard.tasks.messageDeleted' : 'dashboard.consultantTasks.messageDeleted', { name: fromUserName }) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserTaskTypeProcessingActivity',
    props: {
        item: {
            type: Object,
            default: null
        },
        shortDate: {
            type: String,
            default: null
        },
        fullDate: {
            type: String,
            default: null
        },
        checkboxModel: {
            type: Boolean,
            default: false
        },
        isUserTask: {
            type: Boolean,
            default: true
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    computed: {
        fromUserName () {
            return this.item.assigningUserId === null ? this.$t('fromLeaResubmission') : this.item.assigningUserName;
        }
    },
    methods: {
        onClick() {
            if (this.item.entityId) {
                this.$emit('close');
                this.$router.push({
                    name: 'VvtEditProcessingActivity',
                    params: { id: this.item.entityId },
                    query: { workflow: this.item.workflowTaskId },
                })
            }
        }
    }
};
</script>
