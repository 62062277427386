var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"taskState pr-3 cursor-pointer",class:_vm.checkboxModel ? 'left' : '',on:{"click":function($event){return _vm.$emit('toggle-checkbox')}}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.checkboxModel)?_c('v-icon',{attrs:{"medium":"","color":"primary"}},[_vm._v("check")]):_c('v-icon',{attrs:{"medium":""}},[_vm._v("check_box_outline_blank")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s(
                    _vm.checkboxModel
                        ? _vm.$t('dashboard.tasks.markAsUndone')
                        : _vm.$t('dashboard.tasks.markAsDone')
                )}})])],1),_c('div',[_c('div',{staticClass:"pl-0"},[_c('p',{staticClass:"pa-0 ma-0",class:_vm.item.status === 'closed' ? 'line-through' : '',domProps:{"innerHTML":_vm._s(_vm.item.title)}}),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},on),[_c('div',{staticClass:"text-caption",class:{ 'grey--text': _vm.$vuetify.dark },domProps:{"textContent":_vm._s(_vm.shortDate)}})])]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.fullDate)}})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }