<template>

    <div class="pb-3 mb-12">
        <v-card class="v-card--material mx-auto" color="secondary">
            <div class="d-flex--v2 grow flex-wrap">
                <v-sheet
                    color="teal"
                    max-height="90"
                    width="auto"
                    class="text-start v-card--material__heading mb-n6 elevation-6 pa-7"
                >
                    <v-icon large color="white">
                        mdi-calendar-multiselect
                    </v-icon>
                </v-sheet>
                <div class="ml-6">
                    <v-card-title>
                        <span
                            class="text-h6 font-weight-light"
                            v-text="$t('dashboard.consultantTasks.headline')"
                        />
                    </v-card-title>
                </div>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn @click="fetchData(true)" color="primary" icon class="mr-3" :loading="$wait.is('fetch consultant tasks')"><v-icon v-text="'mdi-refresh'" /></v-btn>
                </v-card-actions>
            </div>
            <v-card-text>
                <v-skeleton-loader
                    v-if="$wait.is('fetch consultant tasks')"
                    :type="'sentences'"
                    tile
                    class="mx-auto pt-3"
                    height="54"
                ></v-skeleton-loader>
                <div v-else>
                    <span v-if="!todoTasks.length">
                        {{ $t('dashboard.consultantTasks.noTasks') }}
                    </span>
                    <UserTaskDateGroup
                        :items="todoTasks"
                        v-if="todoTasks.length"
                        :removeable="false"
                        :is-user-task="false"
                        :height="400"
                    />
                </div>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserTaskDateGroup from '@/components/UserTasks/UserTaskDateGroup.vue';

export default {
    name: 'DashboardConsultantTasks',
    components: {
        UserTaskDateGroup,
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            taskItems: 'dashboard/getConsultantTaskItems',
        }),
        todoTasks() {
            return this.taskItems || [];
        }
    },
    methods: {
        ...mapActions({
            fetchConsultantTasks: 'dashboard/fetchConsultantTasks'
        }),
        async fetchData(force = false) {
            this.$wait.start('fetch consultant tasks');
            await this.fetchConsultantTasks({force});
            this.$wait.end('fetch consultant tasks');
        },
    },
    created() {
        this.fetchData(true);
    },
    activated () {
        this.fetchData(true);
    },
}
</script>