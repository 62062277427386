<template>
    <div class="d-flex justify-space-between align-center">
        <UserTaskTypeProcessingActivity
            :shortDate="shortDate"
            :fullDate="fullDate"
            :item="item"
            :checkboxModel="checkboxModel"
            :is-user-task="isUserTask"
            v-if="item.type === 'processingActivity'"
            @close="$emit('close')"
        />
        <UserTaskTypeTodo
            :shortDate="shortDate"
            :fullDate="fullDate"
            :item="item"
            :checkboxModel="checkboxModel"
            @toggle-checkbox="checkboxModel = !checkboxModel"
            v-else-if="item.type === 'todo'"
        />
        <UserTaskTypeFeed
            :shortDate="shortDate"
            :fullDate="fullDate"
            :item="item"
            :checkboxModel="checkboxModel"
            @toggle-checkbox="checkboxModel = !checkboxModel"
            v-else-if="item.type === 'feed'"
        />
        <div v-if="checkboxModel || item.type === 'feed'">
            <v-tooltip
                v-if="item.type === 'feed'"
                color="primary"
                left
                class="d-inline-flex"
            >
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn
                            small
                            icon
                            tag="a"
                            :to="{
                                name: 'CommunityList',
                                params: {
                                    category: item.feedType,
                                    id: item.entityId,
                                },
                            }"
                            target="_blank"
                            class="pa-0 ma-0"
                        >
                            <v-icon color="primary" small>open_in_new</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span
                    v-text="$t('dashboard.community.showEntryDetailsInNewTab')"
                ></span>
            </v-tooltip>
            <v-tooltip
                v-if="removeable && checkboxModel"
                left
                color="error"
                class="d-inline-flex"
            >
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn
                            small
                            icon
                            tag="a"
                            @click="removeTask()"
                            class="pa-0 ml-2 ma-0 mt-n7"
                        >
                           <v-icon color="error">clear</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span v-text="$t('dashboard.tasks.remove')"></span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import UserTaskTypeTodo from './types/UserTaskTypeTodo.vue';
import UserTaskTypeFeed from './types/UserTaskTypeFeed.vue';
import UserTaskTypeProcessingActivity from './types/UserTaskTypeProcessingActivity.vue';
export default {
    name: 'UserTaskItem',
    components: {
        UserTaskTypeTodo,
        UserTaskTypeFeed,
        UserTaskTypeProcessingActivity,
    },
    props: {
        item: {
          type: Object,
          default: null
        },
        shortDate: {
            type: String,
            default: null
        },
        fullDate: {
            type: String,
            default: null
        },
        removeable: {
            type: Boolean,
            default: true
        },
        isUserTask: {
            type: Boolean,
            default: true
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    data() {
        return {
            checkboxModel: !(
                this.item.status === 'open' || this.item.status === 'reopened'
            ),
            hover: false,
        };
    },
    methods: {
        ...mapActions({
            changeContactTaskState: 'dashboard/changeContactTaskState'
        }),
        removeTask() {
            this.changeContactTaskState({
                id: this.item.id,
                status: 'deleted',
            });
        },
    },
    watch: {
        checkboxModel(val) {
            const state = val ? 'closed' : 'reopened';
            this.changeContactTaskState({
                id: this.item.id,
                status: state,
            });
        },
    },
};
</script>
