<template>
    <div class="d-flex flex-row">
        <div
            class="taskState pr-3 cursor-pointer"
            @click="$emit('toggle-checkbox')"
            :class="checkboxModel ? 'left' : ''"
        >
            <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-icon medium color="primary" v-if="checkboxModel"
                            >check</v-icon
                        >
                        <v-icon medium v-else>check_box_outline_blank</v-icon>
                    </div>
                </template>
                <span
                    v-text="
                        checkboxModel
                            ? $t('dashboard.tasks.markAsUndone')
                            : $t('dashboard.tasks.markAsDone')
                    "
                ></span>
            </v-tooltip>
        </div>
        <div>
            <div class="pl-0">
                <p
                    class="pa-0 ma-0"
                    style="width:90%;"
                    v-html="$t('dashboard.tasks.types.feed.title') + ': ' + item.title"
                    :class="item.status === 'closed' ? 'line-through' : ''"
                />
                <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="d-inline-block">
                            <div
                                class="text-caption"
                                :class="{ 'grey--text': $vuetify.dark }"
                                v-text="shortDate"
                            />
                        </div>
                    </template>
                    <span v-text="fullDate"></span>
                </v-tooltip>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserTaskTypeFeed',
    props: {
        item: {
            type: Object,
            default: null
        },
        shortDate: {
            type: String,
            default: null
        },
        fullDate: {
            type: String,
            default: null
        },
        checkboxModel: {
            type: Boolean,
            default: false
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
};
</script>
