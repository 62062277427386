<template>
    <v-virtual-scroll
        :items="items"
        :height="height"
        item-height="84"
        :bench="50"
    >
        <template v-slot:default="{ item, index }">
            <transition name="list-transition" tag="div">
                <span
                    :key="item.id + item.dueDate"
                    class="list-item"
                >
                    <div
                        :key="item.id + item.dueDate"
                        class="pb-0 pt-3 pl-0 pr-1"
                    >
                        <UserTaskItem
                            :shortDate="getDateLeft(item.dueDate)"
                            :fullDate="getFullDate(item.dueDate)"
                            :item="item"
                            :removeable="removeable"
                            :is-user-task="isUserTask"
                            @close="$emit('close')"
                        />
                        <v-divider
                            v-if="index + 1 < items.length"
                            class="mt-2"
                        ></v-divider>
                    </div>
                </span>
            </transition>
        </template>
    </v-virtual-scroll>
</template>

<script>
import UserTaskItem from './UserTaskItem.vue';
import {
    parseISO,
    dateTypes,
    formatDate,
    formatDistanceFrom,
} from '@/utils/dateFns';

export default {
    name: 'UserTaskDateGroup',
    components: {
        UserTaskItem,
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    props: {
        items: {
            type: Array,
            default: null,
        },
        removeable: {
            type: Boolean,
            default: true
        },
        isUserTask: {
            type: Boolean,
            default: true
        },
        height: {
            type: [Number, String],
            default: 400
        }
    },
    methods: {
        getFullDate(dueDate) {
            return (
                this.getToolTipDate(dueDate) +
                ' ' +
                this.$i18n.t('at') +
                ' ' +
                this.getToolTipTime(dueDate)
            );
        },
        getDateLeft(dueDate) {
            return formatDistanceFrom(parseISO(dueDate));
        },
        getToolTipDate(dueDate) {
            return formatDate(parseISO(dueDate), dateTypes.fullDate);
        },
        getToolTipTime(dueDate) {
            return formatDate(parseISO(dueDate), dateTypes.time);
        },
    },
};
</script>
